export const benefits = [
  'Receba um aparelho equivalente ao seu em caso de perda total.',
  'Conserto do aparelho em caso de dano reparável.',
  'Atendimento 24h por dia no 0800 da seguradora.',
]

export const guaranteedCoverage = [
  {
    question: 'Danos ou quebras acidentais',
    firstAnswer:
      'Você tem proteção caso o celular pare de funcionar depois de alguns imprevistos como: queda, quebra acidental de tela, incêndio, explosão ou até mesmo impactos de veículos.',
    secondAnswer:
      'Ao acionar o seguro em caso de danos ou quebras acidentais, a franquia é de 20% do valor dos possíveis consertos ou da reposição do aparelho e deve ser no mínimo R$150,00.',
  },
]

export const additionalCoverage = [
  {
    question: 'Roubo',
    firstAnswer: 'Quando o celular é tirado de você com ameaça ou violência.',
    secondAnswer:
      'Pra acionar o seguro em caso de roubo, é preciso ter o B.O. e demais documentos de sinistro. A franquia é de 25% do valor do aparelho, que deve equivaler a no mínimo R$150,00.',
  },
  {
    question: 'Furto qualificado ',
    firstAnswer:
      'Quando o celular é tirado de você sem ameaça, mas deixando algum vestígio, como sinais de arrombamento.',
    secondAnswer:
      'Pra acionar o seguro em caso de furto qualificado, é preciso ter o B.O e demais documentos de sinistro. A franquia é de 20% do valor do aparelho e você recebe um celular equivalente ao seu.',
  },
  {
    question: 'Danos por água ou líquidos  ',
    firstAnswer: 'Quando acontece um acidente com água ou qualquer outro líquido.',
    secondAnswer:
      'Ao acionar o seguro em caso de danos por líquidos, a franquia é de 25% do valor dos possíveis consertos ou da reposição do aparelho e deve ser de no mínimo R$ 150,00.',
  },
  {
    question: 'Danos elétricos  ',
    firstAnswer: 'Quando o aparelho queima por conta de raios ou oscilações de energia.',
    secondAnswer:
      'Ao acionar o seguro em caso de danos elétricos, a franquia é de 15% do valor dos possíveis consertos ou da reposição do aparelho e deve ser de no mínimo R$ 150,00.',
  },
]
